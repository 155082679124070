import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
} from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import { ProfileApi, Handle } from "./APIClient";


const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world From React App!</div>,
  },
]);

function App() {
  return (
    /*
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/i/about">
            <About />
          </Route>
          <Route exact path="/i/policy">
            <Policy />
          </Route>
          <Route path="/:id" component={HandleA} />
        </Switch>
      </div>
    </Router>
    */
    <RouterProvider router={router} />
  );
}

function Home() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <ul>
        <li>
          <Link to="/i/about">About</Link>
        </li>
        <li>
          <Link to="/i/policy">Policy</Link>
        </li>
        <li>
          <Link to="/martin">Martin</Link>
        </li>
        <li>
          <Link to="/🚀">🚀</Link>
        </li>
        <li>
          <Link to="/NotExistingHandle">NotExistingHandle</Link>
        </li>
      </ul>

      <p>
        Edit <code>src/App.tsx</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header>
  );
}

function About() {
  return (
    <div>
      <h1>About</h1>
      <p>This is cool application</p>
    </div>
  );
}

function Policy() {
  return (
    <div>
      <h1>Policy</h1>
      <p>Do not abuse this application</p>
    </div>
  );
}

/*

interface HandlePropsTypes {
  id: string;
}

interface HandleStateTypes {
  id: string;
  handle?: Handle;
}

class HandleA extends React.Component<
  RouteComponentProps<HandlePropsTypes>,
  HandleStateTypes
> {
  constructor(props: RouteComponentProps<HandlePropsTypes>) {
    super(props);

    this.state = {
      id: props.match.params.id,
      handle: undefined,
    };
  }

  componentDidMount() {
    console.dir(this.props);

    const id = this.state.id;

    const api = new ProfileApi();
    api.profileHandlesRead(id).then((res) => {
      console.log(res);

      const handle = res.data;
      this.setState({
        id: id,
        handle: handle,
      });
    });
  }

  render() {
    return this.state.handle ? (
      <div>
        <h2>{this.state.handle.key}</h2>
        <h3>Blocks</h3>
        {this.state.handle.blocks?.map((block) => (
          <div>
            <h3>{block.position} Block</h3>
            <p>{block.text}</p>
            <p>{block.createdAt}</p>
          </div>
        ))}
      </div>
    ) : (
      <div>Handle {this.props.match.params.id} not found!</div>
    );
  }
}

*/

export default App;
